// icon:bxl-docker | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

function IconBxlDocker(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill=" #0db7ed"
      height="7em"
      width="7em"
      {...props}
    >
      <path d="M20.17 9.82a4.76 4.76 0 00-.84.07 3.12 3.12 0 00-1.43-2.14l-.28-.16-.19.27a3.7 3.7 0 00-.51 1.19 2.84 2.84 0 00.33 2.22 4.11 4.11 0 01-1.45.35H2.63a.63.63 0 00-.63.62 9.6 9.6 0 00.58 3.39 5 5 0 002 2.6 8.86 8.86 0 004.42.95 13.27 13.27 0 002.42-.18 10.09 10.09 0 003.19-1.15A8.9 8.9 0 0016.78 16a11.94 11.94 0 002.13-3.67h.19a3.08 3.08 0 002.23-.84 2.36 2.36 0 00.59-.87l.08-.22-.2-.16a2.69 2.69 0 00-1.63-.42z" />
      <path d="M5.61 9.35H3.85a.16.16 0 00-.16.15v1.58a.16.16 0 00.16.15h1.76a.16.16 0 00.16-.15V9.5a.16.16 0 00-.16-.15zm2.44 0H6.28a.16.16 0 00-.16.15v1.58a.16.16 0 00.16.15h1.77a.15.15 0 00.15-.15V9.5a.15.15 0 00-.15-.15zm2.47 0H8.75a.15.15 0 00-.15.15v1.58a.15.15 0 00.15.15h1.77a.15.15 0 00.15-.15V9.5a.15.15 0 00-.15-.15zm.67 0a.15.15 0 00-.19.15v1.58a.15.15 0 00.15.15H13a.15.15 0 00.15-.15V9.5a.15.15 0 00-.15-.15zM6.28 7.09H8a.16.16 0 01.16.16v1.56A.16.16 0 018 9H6.28a.15.15 0 01-.15-.15V7.24a.15.15 0 01.15-.15zm2.47 0h1.77a.15.15 0 01.15.15v1.57a.16.16 0 01-.16.16H8.75a.15.15 0 01-.15-.15V7.24a.15.15 0 01.15-.15zm2.44 0H13a.15.15 0 01.15.15v1.57A.15.15 0 0113 9h-1.81a.16.16 0 01-.19-.19V7.24a.15.15 0 01.19-.15z" />
      <path d="M11.19 4.82 H12.959999999999999 A0.15 0.15 0 0 1 13.11 4.970000000000001 V6.55 A0.15 0.15 0 0 1 12.959999999999999 6.7 H11.19 A0.15 0.15 0 0 1 11.04 6.55 V4.970000000000001 A0.15 0.15 0 0 1 11.19 4.82 z" />
      <path d="M13.65 9.35a.15.15 0 00-.15.15v1.58a.15.15 0 00.15.15h1.77a.15.15 0 00.15-.15V9.5a.15.15 0 00-.15-.15z" />
    </svg>
  );
}

export default IconBxlDocker;
